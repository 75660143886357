import {initializeApp} from 'firebase/app';
import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBpM3Ky8FOuKgL4St83KhKO2q-0eT1mSoc",
    authDomain: "rent-camp-5cc50.firebaseapp.com",
    projectId: "rent-camp-5cc50",
    storageBucket: "rent-camp-5cc50.appspot.com",
    messagingSenderId: "1090603300867",
    appId: "1:1090603300867:web:dc41ef60b51c75165bb636",
    measurementId: "G-4B7ZC7VNJ0"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore();
