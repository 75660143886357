import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { Link } from 'react-router-dom'
import { db } from '../firebase'

const Styled = {
  wrap: styled.div`
    background-color: #f3f3f3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 28px;
    text-align: center;
    word-break: keep-all;;
  `,
  menuItemList: styled.div`
    display: flex;
  `,
  menuItem: styled.a`
    font-size: 13px;
    color: #959597;
  `,
  divider: styled.div`
    width: 1px;
    height: 10px;
    background-color: #dedede;
    margin: 10px 10px;
  `,
  copyright: styled.div`
    font-size: 13px;
    margin-top: 14px;
    color: #959597;
  `,
  content: styled.div`
    display: flex;
    font-size: 13px;
    flex-direction: column;
    align-items: center;
    color: #959597;
    margin-bottom: 14px;
    line-height: 1.6;
  `,
}

interface FooterProps {
  className?: string
}

const Footer: React.FC<FooterProps> = (props) => {
  const year = useMemo(function getYear(): number {
    return new Date().getFullYear()
  }, [])

  // 사업자 등록 정보
  const [footerContent, setFooterContent] = useState('')
  useEffect(() => {
    async function fetch() {
      const termsRef = collection(db, 'terms')
      const termQuery = query(termsRef, where('type', '==', 'homepageFooter'))
      const snapshot = await getDocs(termQuery)
      const document = snapshot.docs[0]
      setFooterContent(document.get('body'))
    }

    fetch().catch()
  }, [])

  return (
    <Styled.wrap className={props.className}>
      <Styled.content dangerouslySetInnerHTML={{ __html: footerContent }} />
      <Styled.menuItemList>
        <Link to={'/agreement/service'}>
          <Styled.menuItem>서비스 이용약관</Styled.menuItem>
        </Link>
        <Styled.divider />
        <Link to={'/agreement/privacyPolicy'}>
          <Styled.menuItem>개인정보 처리방침</Styled.menuItem>
        </Link>
      </Styled.menuItemList>
      <Styled.copyright>
        © {year} YONGHA Co. All Rights Reserved.
      </Styled.copyright>
    </Styled.wrap>
  )
}

export default Footer
