import React, { useEffect } from 'react'
import styled from 'styled-components'

interface PopupProps {
  id: number;
  show: boolean;
  title: string;
  hasDesktopImage?: boolean;
  showNeverShowAgain: boolean;
  onNeverShowAgain?: (id: number) => void;
  onConfirm: (id: number) => void;
}

interface PopupContainerProps {
}

interface PopupButtonProps {
  primary: boolean
}

const Styled = {
  wrap: styled.div`
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20.4823px 13.6548px;
    gap: 20.48px;

    /* White */

    background: #FFFFFF;
    /* Border/2 */

    border: 0.853428px solid #DCE1E5;
    border-radius: 14.5083px;

    @media (min-width: 768px) {
      width: 620px;
    }

    @media (max-width: 768px) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 5%;
      right: 5%;
    }
  `,
  title: styled.div`
    /* Header/3 */

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: -0.02em;

    /* Gray/90 */

    color: #171717;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    white-space: pre-wrap;
  `,
  buttonWrap: styled.div`
    display: flex;
    
    @media (max-width: 640px) {
      gap: 8px;
    }
    gap: 24px;
  `,
  button: styled.div<PopupButtonProps>`
    flex: 1;
    display: flex;
    justify-content: center;


    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    gap: 10px;

    width: 134.5px;
    height: 60px;

    /* Primary/60 ★ */

    background: ${(props) => props.primary ? '#00A3FF' : '#E8EBED'};

    border-radius: 13px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 1;

    /* Title/2 */

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    align-items: center;
    letter-spacing: -0.02em;
    word-break: keep-all;

    /* Grayscale/White */

    text-align: center;
    color: ${(props) => props.primary ? 'white' : '#72787F'};
  `,
  mediaWrap: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
  `,
  media: styled.img`
    height: 100%;
  `,
}

const StyledContainer = {
  wrap: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;

    @media (max-width: 768px) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      gap: 0;
    }

    @media (min-width: 768px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `,
}

export const PopupContainer: React.FC<PopupContainerProps> = ({ children }) => {
  return <StyledContainer.wrap>
    {children}
  </StyledContainer.wrap>
}

export const Popup: React.FC<PopupProps> = (props) => {
  const [width, setWidth] = React.useState(window.outerWidth)
  const ref = React.useRef<HTMLDivElement>(null)
  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0)
  }, [ref.current])

  return (props.show ? <Styled.wrap ref={ref}>
    <Styled.title>{props.title}</Styled.title>
    <Styled.mediaWrap>
      <Styled.media
        src={props.hasDesktopImage && window.outerWidth >= 440 ? `/images/popup-${props.id}.svg` : `/images/popup-mobile-${props.id}.svg`} />
    </Styled.mediaWrap>
    <Styled.buttonWrap>
      {props.showNeverShowAgain && <Styled.button
        onClick={() => props.onNeverShowAgain && props.onNeverShowAgain(props.id)}
        primary={false}
      >오늘 다시 보지 않기</Styled.button>}
      <Styled.button
        onClick={() => props.onConfirm(props.id)}
        primary>확인</Styled.button>
    </Styled.buttonWrap>
  </Styled.wrap> : <></>)
}


