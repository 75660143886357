import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import Agreement from './pages/Agreement'
import Sharer from './pages/Sharer'
import User from './pages/User'
import { Popup, PopupContainer } from './components/Popup'

function App() {
  let vh = 0

  // 최초 실행시 팝업 24시간 동안 보이지 않도록 설정된 항목 숨김 처리
  const initialPopups = [
    {
      title: '렌트캠 \n' +
        '국민대학교 산학협력 가족회사 협약',
      showNeverShowAgain: true,
      id: 1,
      order: 1,
      isUser: true,
    },
    {
      title: '과학기술정보통신부&국토교통부 주제\n' +
        '샌드박스 정식 허가',
      showNeverShowAgain: true,
      id: 2,
      order: 2,
      isUser: true,
    },
    {
      title: '랜트캠 이용 안내',
      hasDesktopImage: true,
      showNeverShowAgain: false,
      id: 3,
      order: 3,
      isUser: false,
    },
  ]
  const filteredInitialPopups = initialPopups.filter((popup) => {
    const popupId = popup.id
    const neverShowAgainAt = localStorage.getItem(`popup-never-show-again-${popupId}`)
    if (!neverShowAgainAt) return true

    // 24시간 지났는지 확인
    if (Date.parse(neverShowAgainAt) + 24 * 60 * 60 * 1000 < Date.now()) {
      return true
    }

    return false
  })

  useEffect(() => {
    vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, [])

  // Load Popup Items
  const [popups, setPopups] = useState(filteredInitialPopups)

  const popupNeverShowAgain = (id: number) => {
    localStorage.setItem(`popup-never-show-again-${id}`, new Date().toISOString())
    hidePopup(id)
  }

  const hidePopup = (id: number) => {
    setPopups(popups.filter((popup) => popup.id !== id))
  }

  return (
    <div>
      <Switch>
        <Route path='/agreement/:type'>
          <Agreement />
        </Route>
        <Route path='/sharer'>
          <Sharer />
          <PopupContainer>
            {
              popups.filter((popup) => !popup.isUser)
                .sort((a, b) => b.order - a.order)
                .map((popup, index) => <Popup
                  key={popup.id}
                  id={popup.id}
                  title={popup.title}
                  show={true}
                  hasDesktopImage={popup.hasDesktopImage}
                  showNeverShowAgain={popup.showNeverShowAgain}
                  onNeverShowAgain={(id) => popupNeverShowAgain(id)}
                  onConfirm={(id) => hidePopup(id)}
                />)
            }
          </PopupContainer>
        </Route>
        <Route path='/'>
          <User />
          {popups.filter((popup) => popup.isUser).length > 0 ? <PopupContainer>
            {
              popups.filter((popup) => popup.isUser)
                .sort((a, b) => b.order - a.order)
                .map((popup, index) => <Popup
                  key={popup.id}
                  id={popup.id}
                  title={popup.title}
                  show={true}
                  hasDesktopImage={popup.hasDesktopImage}
                  showNeverShowAgain={popup.showNeverShowAgain}
                  onNeverShowAgain={(id) => popupNeverShowAgain(id)}
                  onConfirm={(id) => hidePopup(id)}
                />)
            }
          </PopupContainer> : <></>}
        </Route>
      </Switch>
    </div>
  )
}

export default App
