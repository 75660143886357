import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { collection, getDocs, query, where } from 'firebase/firestore'
import styled from 'styled-components'
import { db } from '../firebase'

const Styled = {
  wrap: styled.div`
    margin: 24px 36px;
    line-height: 1.6;
    font-size: 15px;
  `,
}

interface AgreementParams {
  type: string;
}

function Agreement(): ReactElement {
  const { type } = useParams<AgreementParams>()

  const [content, setContent] = useState('')
  useEffect(() => {
    async function fetch() {
      if (!type) {
        return
      }
      const termCollection = collection(db, 'terms')
      const termQuery = query(termCollection, where('type', '==', type))
      const snapshot = await getDocs(termQuery)
      const document = snapshot.docs[0]
      setContent(document.get('body'))
    }

    fetch().catch()
  }, [type])

  return (
    <Styled.wrap>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Styled.wrap>
  )
}

export default Agreement
