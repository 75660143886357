import React from 'react'
import axios from 'axios'

const SharerMobile = ({
  menu,
  third,
  ask,
  faq,
  setMenu,
  setAsk,
  setThird,
  setVisible,
}: {
  menu: number
  third: boolean
  ask: any
  faq: any[]
  setMenu: (number: number) => void
  setAsk: (ask: any) => void
  setThird: (third: boolean) => void
  setVisible: (idx: number) => void
}) => {
  return (
    <>
      <div
        style={{ backgroundImage: 'url("/images/bg-2.jpg")' }}
        className="w-full h-full bg-no-repeat bg-center flex bg-cover justify-center items-center flex-col"
      >
        <div className="flex flex-col w-screen min-h-screen items-center  justify-evenly  ">
          <div className="Display2Bold   text-white text-center">
            많은 사람들이 <br className="" /> 여행을 통해
            <br />
            힐링 됐으면 좋겠습니다
          </div>

          <img className=" w-5/6" src="/images/chatbubble-desktop.svg" alt="" />

          <div style={{ display: 'flex', gap: '26px' }}>
            <a
              href='https://play.google.com/store/apps/details?id=kr.rentcamp.app'
              target="_blank"
            >
              <img
                className="w-28.5 "
                src="/images/img-googleplay.svg"
                alt=""
              />
            </a>
            <a
              href='https://apps.apple.com/us/app/렌트캠-rentcamp/id1633095843'
              target="_blank"
            >
              <img className="w-28.5 " src="/images/img-appstore.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div
        className="w-full min-h-screen justify-center bg-no-repeat bg-center flex bg-cover items-center flex-col gap-10 relative"
        style={{ backgroundImage: 'url(/images/bg-3.jpg)' }}
      >
        <div className="flex flex-col w-screen min-h-screen items-center justify-center">
          <div className="pl-6 pr-6 gap-10 flex justify-center flex-col min-h-screen">
            <div
              className="font-bold"
              style={{
                fontSize: '34px',
              }}
            >
              안전한 수익구조의 렌트캠
            </div>
            <div className="flex flex-col gap-8">
              <div className="flex gap-4 flex-col">
                <div className="flex gap-2">
                  <img className="w-6" src="/images/ic-check.svg" />
                  <div className="Title1Bold text-primary-60">안정성</div>
                </div>
                <div className="text-gray-90 Title1Medium">
                  과학기술정보통신부, 국토교통부를 통한 실증특례 규제샌드박스
                  허가
                </div>
              </div>
              <div className="flex gap-4 flex-col">
                <div className="flex gap-2">
                  <img className="w-6" src="/images/ic-check.svg" />
                  <div className="Title1Bold text-primary-60">수익성</div>
                </div>
                <div className="text-gray-90 Title1Medium">
                  월 8회, 5년간 수익 1억원 이상 (대여료 25만원 기준, 대여료에 따라
                  수익금은 증감가능)
                </div>
              </div>
              <div className="flex gap-4 flex-col">
                <div className="flex gap-2">
                  <img className="w-6" src="/images/ic-check.svg" />
                  <div className="Title1Bold text-primary-60">효율성</div>
                </div>
                <div className="text-gray-90 Title1Medium">
                  내가 사용하지 않을 때 빌려주어 효율성 보장
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-full min-h-screen justify-center bg-no-repeat bg-center flex bg-cover items-center flex-col gap-10 relative"
        style={{ backgroundImage: 'url(/images/bg-3.jpg)' }}
      >
        <div className="flex flex-col w-screen min-h-screen items-center justify-center">
          <div className="pl-6 pr-6 gap-10 flex justify-center flex-col min-h-screen">
            <div className="w-full min-h-screen flex items-center">
              <div
                className="w-full pt-16 border-border-2"
                style={{
                  borderRadius: '1.5rem',
                  boxShadow: '0 3px 5px 0 rgba(38, 40, 43, 0.04)',
                }}
              >
                <div className="flex flex-col gap-6 mb-6.5">
                  <div className="Header1 ">예시 월 수익</div>
                  <div className="flex gap-4 w-fit">
                    <button
                      className="border-none bg-none outline-none"
                      onClick={() => setMenu(1)}
                    >
                      <img
                        className="w-24"
                        src={
                          menu === 1
                            ? '/images/motorhome-active.svg'
                            : '/images/motorhome-inactive.svg'
                        }
                      />
                    </button>
                    <button
                      className="border-none bg-none outline-none"
                      onClick={() => setMenu(2)}
                    >
                      <img
                        className="w-36.5"
                        src={
                          menu === 2
                            ? '/images/motorhome+option-active.svg'
                            : '/images/motorhome+option-inactive.svg'
                        }
                      />
                    </button>
                    <button
                      className="border-none bg-none outline-none"
                      onClick={() => setMenu(3)}
                    >
                      <img
                        className="w-24"
                        src={
                          menu === 3
                            ? '/images/campervan-active.svg'
                            : '/images/campervan-inactive.svg'
                        }
                      />
                    </button>
                  </div>
                </div>
                {menu === 1 ? (
                  <img
                    className="w-full"
                    src="/images/img-graph-motorhome-mobile.svg"
                  />
                ) : null}
                {menu === 2 ? (
                  <img
                    className="w-full"
                    src="/images/img-graph-motorhome-option-mobile.svg"
                  />
                ) : null}
                {menu === 3 ? (
                  <img
                    className="w-full"
                    src="/images/img-graph-campervan-mobile.svg"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-full min-h-screen bg-no-repeat bg-center justify-center flex bg-cover items-center flex-col relative"
        style={{ backgroundImage: 'url("/images/bg-4.jpg")' }}
      >
        <div className="flex w-screen min-h-screen items-center justify-center gap-5.5 flex-col pt-16 pl-6 pr-6">
          <div className="Header1">공유자 등록 안내</div>
          <div className="bg-white rounded overflow-hidden">
            <div className="grid h-10 grid-cols-2">
              <button
                className={`Title4Bold ${
                  !third ? 'text-primary-60' : 'text-gray-60 bg-background-dark'
                }`}
                onClick={() => setThird(false)}
              >
                캠핑카 구매 희망자
              </button>
              <button
                className={`Title4Bold ${
                  third ? 'text-primary-60' : 'text-gray-60 bg-background-dark'
                }`}
                onClick={() => setThird(true)}
              >
                캠핑카 보유자
              </button>
            </div>
            {!third ? (
              <>
                <div className="flex pt-6 pl-4 pr-4 flex-col items-center gap-1.5">
                  <div className="flex w-full bg-background-light rounded-xl flex-col gap-3 pt-3.5 pl-3 pr-3 pb-3.5">
                    <div className="flex items-center">
                      <img
                        className="w-6 mr-3"
                        src="/images/icon-info-1-primary.svg"
                      />
                      <div className="text-gray-90 Title3Bold">
                        렌트캠 공유자 가입 문의
                      </div>
                    </div>
                    <div className="Title4Medium">
                      유선, 이메일, 카톡을 통한 상담 진행
                    </div>
                  </div>
                  <img className="w-8" src="/images/arrow.svg" />
                  <div className="flex w-full bg-background-light rounded-xl flex-col gap-3 pt-3.5 pl-3 pr-3 pb-3.5">
                    <div className="flex items-center">
                      <img
                        className="w-6 mr-3"
                        src="/images/icon-info-2-primary.svg"
                      />
                      <div className="text-gray-90 Title3Bold">
                        렌트캠 공유자 계약 및 <br className="hidden" />
                        캠핑카 구입 진행
                      </div>
                    </div>
                    <div className="Title4Medium">
                      렌트캠 공유 계약 작성 후 원하는 제작업체에서 차량 출고
                    </div>
                  </div>
                  <img className="w-8" src="/images/arrow.svg" />
                  <div className="flex w-full bg-background-light rounded-xl flex-col gap-3 pt-3.5 pl-3 pr-3 pb-3.5 mb-5">
                    <div className="flex items-center">
                      <img
                        className="w-6 mr-3"
                        src="/images/icon-info-3-primary.svg"
                      />
                      <div className="text-gray-90 Title3Bold">
                        캠핑카 출고 후
                      </div>
                    </div>
                    <div className="Title4Medium">
                      캠핑카 출고 후 사용하지 않는 기간 동안 렌트캠 공유를 통한
                      수익 창출
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {third ? (
              <div className="flex flex-col items-center justify-center pt-6 gap-6 pb-21 pl-4 pr-4">
                <div className="flex flex-col gap-4.5 items-center">
                  <img className="w-6" src="/images/ic-bang.svg" />
                  <div className="text-primary-80 Title3Bold">
                    출고후 3년이내 차량만 등록 가능
                  </div>
                </div>

                <div className="flex w-full pl-4 pr-4 flex-col items-center gap-1.5">
                  <div className="flex w-full bg-background-light rounded-xl flex-col gap-3 pt-3.5 pl-3 pr-3 pb-3.5">
                    <div className="flex items-center">
                      <img
                        className="w-6 mr-3"
                        src="/images/icon-info-1-secondary.svg"
                      />
                      <div className="Title3Bold text-gray-90">
                        렌트캠 공유자 가입 문의
                      </div>
                    </div>
                    <div className="Title4Medium">
                      유선, 이메일, 카톡을 통한 상담 진행
                    </div>
                  </div>
                  <img className="w-8" src="/images/arrow.svg" />
                  <div className="flex w-full bg-background-light rounded-xl flex-col gap-3 pt-3.5 pl-3 pr-3 pb-3.5">
                    <div className="flex items-center">
                      <img
                        className="w-6 mr-3"
                        src="/images/icon-info-2-secondary.svg"
                      />
                      <div className="Title3Bold text-gray-90">
                        서류 작성
                      </div>
                    </div>
                    <div className="Title4Medium">렌트캠 공유자 계약서 작성</div>
                  </div>
                  <img className="w-8" src="/images/arrow.svg" />
                  <div className="flex w-full bg-background-light rounded-xl flex-col gap-3 pt-3.5 pl-3 pr-3 pb-3.5">
                    <div className="flex items-center">
                      <img
                        className="w-6 mr-3"
                        src="/images/icon-info-3-secondary.svg"
                      />
                      <div className="Title3Bold text-gray-90">
                        캠핑카 출고 후
                      </div>
                    </div>
                    <div className="Title4Medium">
                      사용하지 않는 기간 동안 렌트캠 공유를 통한 수익 창출
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div
        className="w-full min-h-screen bg-no-repeat bg-center flex bg-cover items-center flex-col relative gap-5.5 pt-16 pl-6 pr-6 overflow-hidden pb-10"
        style={{
          backgroundImage: 'url("/images/bg-3.jpg")',
        }}
      >
        <div className="text-gray-80 Header1 mt-5.5">
          궁금한 점이 있으신가요?
        </div>
        <div className="w-full bg-white border-t-2 border-b border-border-2">
          {faq.map((val, idx) => {
            return (
              <div
                key={idx}
                onClick={() => {
                  setVisible(idx)
                }}
              >
                <div className="  h-16 border-b border-border-2 flex flex-row items-center justify-between pl-4 pr-3.5">
                  <div className="  Title3Medium text-gray-90 flex flex-row items-center">
                    <img className="w-6 mr-8.5" src="/images/ic-question.svg" />
                    {val.title}
                  </div>
                  <img
                    className={`w-6 ${val.visible ? 'rotate-180' : ''}`}
                    src={'/images/arrow-up-s-line.svg'}
                  />
                </div>
                <div
                  className={`bg-background-dark ${
                    val.visible ? '' : 'hidden'
                  } flex pl-4.5 pt-5 pb-5 pr-3.5 items-start   Body3`}
                >
                  <img className="w-6 mr-8.5" src="/images/ic-answer.svg" />
                  {val.content}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div
        className="w-full bg-no-repeat bg-cover bg-center flex justify-center items-center flex-col relative min-h-screen   pt-16  gap-5.5  pl-6 pr-6  "
        style={{
          backgroundImage: 'url("/images/bg-4.jpg")',
        }}
      >
        <div className="Header1 mt-5.5">공유자 가입 문의</div>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            axios.post('/mail', ask).then((res) => {
              alert(res.data.message)
              setAsk({
                name: '',
                phone: '',
                email: '',
                campingcar: true,
                content: '',
              })
            })
          }}
          className="w-full p-7.5 bg-white rounded-lg border border-border-2 flex flex-col gap-5"
          style={{ boxShadow: '0 3px 5px 0 rgba(38, 40, 43, 0.04)' }}
        >
          <input
            className="border border-border-2 rounded h-11 pl-2.5 placeholder:text-gray-70 outline-none text-xs "
            type="text"
            value={ask.name}
            onChange={(e) => setAsk({ ...ask, name: e.currentTarget.value })}
            placeholder="성함"
            required
          />
          <input
            className="border border-border-2 rounded h-11 pl-2.5 placeholder:text-gray-70 outline-none text-xs "
            type="text"
            value={ask.phone}
            onChange={(e) => setAsk({ ...ask, phone: e.currentTarget.value })}
            placeholder="연락처"
            required
          />
          <input
            className="border border-border-2 rounded h-11 pl-2.5 placeholder:text-gray-70 outline-none text-xs"
            type="text"
            value={ask.email}
            onChange={(e) => setAsk({ ...ask, email: e.currentTarget.value })}
            placeholder="이메일"
            required
          />
          <div className="flex gap-3">
            <div
              className={`flex items-center w-full h-11 border rounded ${
                ask.campingcar
                  ? 'border-primary-60'
                  : 'border-border-2 bg-background-light'
              }`}
              onClick={() => setAsk({ ...ask, campingcar: true })}
            >
              <img
                className="w-4.5 ml-1.5 mr-1.5"
                src={
                  ask.campingcar
                    ? '/images/ic-radio-active.svg'
                    : '/images/ic-radio-inactive.svg'
                }
              />
              <div
                className={`${ask.campingcar ? 'Title5Bold' : 'Title5Medium'}`}
              >
                캠핑카 보유중
              </div>
            </div>
            <div
              className={`flex items-center w-full h-11 border rounded ${
                !ask.campingcar
                  ? 'border-primary-60'
                  : 'border-border-2 bg-background-light'
              }`}
              onClick={() => setAsk({ ...ask, campingcar: false })}
            >
              <img
                className="ml-1.5 mr-1.5 w-4.5"
                src={
                  !ask.campingcar
                    ? '/images/ic-radio-active.svg'
                    : '/images/ic-radio-inactive.svg'
                }
              />
              <div
                className={`${
                  !ask.campingcar ? '  Title5Bold' : '  Title5Medium'
                }`}
              >
                캠핑카 구매예정
              </div>
            </div>
          </div>
          <textarea
            className="w-full h-46.5 border border-border-2 rounded pt-4 pb-4 pl-3 pr-4 placeholder:text-gray-70 outline-none text-xs"
            value={ask.content}
            onChange={(e) => setAsk({ ...ask, content: e.currentTarget.value })}
            placeholder="문의사항"
            required
          />
          <button
            type="submit"
            className="mt-7 bg-primary-60 text-white rounded h-10 Title3Bold"
          >
            문의 전송
          </button>
        </form>
      </div>
    </>
  )
}

export default SharerMobile
