import React, { BaseSyntheticEvent, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useInterval } from 'usehooks-ts'
import styled from 'styled-components'
import Footer from '../components/Footer'
import CursorImg from '../components/CursorImg'

const Slide = ({
                 count,
                 slideList,
                 slideRef,
                 delayEnabled,
               }: {
  delayEnabled: boolean
  count: number
  slideList: any[]
  slideRef: React.LegacyRef<HTMLUListElement>
}) => {
  return (
    <>
      <ul
        ref={slideRef}
        className={`slideWrap flex w-330 sm:w-366 xl:w-510 transition-all duration-1000 ${
          delayEnabled ? 'delay-1000' : ''
        }`}
      >
        {slideList.map((slide, idx) => (
          <li key={idx}>
            <img width={'100%'} src={slide.src} alt={slide.alt} />
          </li>
        ))}
      </ul>
    </>
  )
}

const User = () => {
  const slideRef = useRef<any>()
  const [count, setCount] = useState(0)
  const [play, setPlay] = useState(true)
  const [manualTriggerTimer, setManualTriggerTimer] = useState<any>(null)
  const [slideList, setSlideList] = useState([
    { src: '/images/img-mockup-1.png' },
    { src: '/images/img-mockup-2.png' },
    { src: '/images/img-mockup-3.png' },
    { src: '/images/img-mockup-4.png' },
    { src: '/images/img-mockup-5.png' },
    { src: '/images/img-mockup-6.png' },
  ])
  const history = useHistory()

  useInterval(() => {
    if (play) {
      if (count < slideList.length - 1) {
        setCount(count + 1)
      } else {
        setCount(0)
      }
    }
  }, 2000)

  useEffect(() => {
    const width = slideRef.current.offsetWidth / 6
    if (count === slideList.length) {
      slideRef.current.style.transform = 'translateX(0)'
    } else {
      slideRef.current.style.transform = `translateX(-${width * count}px)`
    }
  }, [count, slideList.length])

  const handleLoad = (e: BaseSyntheticEvent) => {
    if (!e.currentTarget.classList.contains('active')) {
      e.currentTarget.classList.add('active')
    }
  }

  const handleSlide = (idx: number) => {
    setPlay(false)
    if (idx < 0) {
      setCount(5)
    } else if (idx < slideList.length) {
      setCount(idx)
    } else {
      setCount(0)
    }

    // Timer
    if (manualTriggerTimer) clearTimeout(manualTriggerTimer)
    setManualTriggerTimer(setTimeout(() => setPlay(true), 1000))
  }

  return (
    <>
      <Container
        onLoad={handleLoad}
        className='w-full min-h-screen sm:h-screen bg-no-repeat bg-center flex bg-cover items-center flex-col overflow-hidden'
        style={{ backgroundImage: 'url("/images/bg-1.jpg")' }}
      >
        <div
          className='w-full h-16 sm:h-18 xl:h-21 flex items-center relative justify-end xl:justify-center pr-6 sm:pr-30 xl:pr-0'>
          <CursorImg
            className='absolute hidden sm:block sm:left-30.5 xl:left-36'
            src='/images/icon-nav-logo.svg'
            onClick={() => history.push('/')}
            alt=''
          />
          <CursorImg
            className='absolute sm:hidden left-6'
            src='/images/group-523.svg'
            onClick={() => history.push('/')}
            alt=''
          />
          <div className='flex gap-8'>
            <button className='sm:Header2 Title1Bold flex items-center gap-2 text-primary-60'>
              <img
                className='sm:w-8 w-6'
                src='/images/icon-nav-user-active.svg'
                alt=''
              />
              이용자
            </button>
            <button
              className='sm:Header2 Title1Bold flex items-center gap-2 text-gray-40'
              onClick={() => history.push('/sharer')}
            >
              <img
                className='sm:w-8 w-6'
                src='/images/icon-nav-share-inactive.svg'
                alt=''
              />
              공유자
            </button>
          </div>
        </div>
        <div className='flex w-screen sm:h-screen items-center justify-evenly flex-col sm:flex-row'>
          <div className='flex flex-col items-center sm:items-start'>
            <div className='Display3Light sm:Display2Light xl:Display1Light text-white sm:mb-4.5 mb-1.5'>
              추억을 만드는 여행
            </div>
            <div className='Display3SemiBold sm:Display2Semibold-2 xl:Display1Semibold text-white sm:mb-8 mb-5.5'>
              캠핑카 공유 플랫폼
            </div>
            <img
              className='w-40 xl:w-48.5 sm:mb-14 mb-8.5'
              src='/images/logo-typo.svg'
              alt=''
            />
            <img
              className='hidden sm:block -ml-4 w-81 xl:w-109 mb-12'
              src='/images/chatbubble-1.svg'
              alt=''
            />
            <div className='inner mb-12 hidden sm:block'>
              <div className='pagination flex gap-4'>
                {slideList.map((button, idx) => (
                  <div
                    key={idx}
                    className='w-6 h-2 rounded-3xl'
                    onClick={() => {
                      setCount(idx)
                    }}
                    style={{
                      backgroundColor: idx === count ? '#00a3ff' : 'var(--white)',
                    }}
                  ></div>
                ))}
              </div>
            </div>
            <div className='flex gap-6.5 mb-8.5 sm:mb-0'>
              <a
                href='https://play.google.com/store/apps/details?id=kr.rentcamp.app'
                target="_blank"
              >
                <img
                  className='w-28.5 sm:w-35'
                  src='/images/img-googleplay.svg'
                  alt=''
                />
              </a>
              <a
                href='https://apps.apple.com/us/app/렌트캠-rentcamp/id1633095843'
                target="_blank"
              >
                <img
                  className='w-28.5 sm:w-35'
                  src='/images/img-appstore.svg'
                  alt=''
                />
              </a>
            </div>
          </div>
          <div className='flex relative'>
            <button
              className='absolute -left-16 sm:-left-21 top-1/2'
              style={{ transform: 'translateY(-50%)' }}
              onClick={() => handleSlide(count - 1)}
            >
              <img className='w-11' src='/images/Prev.svg' />
            </button>
            <div className='xl:w-85 xl:h-173.5 sm:w-61 sm:h-125 w-55 h-112.5 overflow-hidden'>
              <Slide
                slideRef={slideRef}
                count={count}
                slideList={slideList}
                delayEnabled={play}
              />
            </div>
            <button
              className='absolute -right-16 sm:-right-21 top-1/2'
              style={{ transform: 'translateY(-50%)' }}
              onClick={() => handleSlide(count + 1)}
            >
              <img className='w-11' src='/images/Next.svg' />
            </button>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  )
}

const Container = styled.div`
  > div:nth-child(2) {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 2s all ease;
  }

  &.active > div:nth-child(2) {
    transform: translateY(0);
    opacity: 1;
  }
`

export default User
