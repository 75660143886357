import React, { BaseSyntheticEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import SharerMobile from '../components/sharer-mobile'
import styled from 'styled-components'
import { collection, doc, setDoc } from 'firebase/firestore'
import { db } from '../firebase'
import Footer from '../components/Footer'
import CursorImg from '../components/CursorImg'
import { useInView } from 'react-intersection-observer'

const Sharer = () => {
  const [headerColor, setHeaderColor] = useState('')
  const [menu, setMenu] = useState(1)
  const [third, setThird] = useState(false)
  const [faq, setFaq] = useState([
    {
      title: '렌트캠은 어떤 서비스인가요?',
      content:
        '캠핑카를 소유하고 있는 사람(공유자)과 캠핑카를 사용하려는 사람 (수요자)를 이어주어 공유자의 불편과 소비자의 수요를 충족시키기 위해 만들어진 캠핑카 공유서비스입니다.',
      visible: false,
    },
    {
      title: '캠핑카 공유는 처음 들어보는데 수익이 날까요?',
      content:
        '홈 공유 서비스인 에어비앤비를 연상하시면 쉽습니다. 렌트캠은 모터+홈을 공유하는 것입니다. 렌트캠에 기본료와 수수료 및 세금을 납부한 나머지 수익은 모두 공유자에게 돌아갑니다. 예상 수익표는 공유자 탭을 확인해 주세요!',
      visible: false,
    },
    {
      title: '렌트캠에 등록하면 내가 원하지 않을 때도 빌려줘야 하나요?',
      content:
        '전혀 아닙니다. 내가 빌려주고자 하는 날짜를 설정하여 원하는 날짜에만 빌려주시면 됩니다.',
      visible: false,
    },
    {
      title: '캠핑카 공유(대여) 비용은 회사가 정하나요?',
      content:
        '아닙니다. 공유자가 원하는 비용을 책정하시면 됩니다. 하지만, 회사의 모토는 “많은 사람들이 여행을 통해 힐링 됐으면 좋겠습니다.”이기 때문에 저렴한 금액으로 대여해 주시길 권장 드립니다.',
      visible: false,
    },
    {
      title: '가입 과정이 번거롭지 않나요?',
      content:
        '렌트캠에서 요청드리는 몇가지 서류만 준비해 주시면 되며 번거로운 일은 렌트캠이 직접 처리하고 있습니다. 기존 차량 보유자의 경우 출고 후 3년 이내 차량만 가입이 가능하니 이점 유의해 주세요.',
      visible: false,
    },
    {
      title: '가입시기가 정해져있나요?',
      content:
        '현재는 시기를 정해 두진 않았습니다. 하지만 렌트캠 자체의 수용 한계로 가입하고 싶어도 가입하지 못하는 상황이 발생할 수 있습니다. 선착순으로 가입자를 받고 있으며, 공유자의 경우 조기 시장 진입 시 장기간 수익을 실현할 수 있는 장점이 있으니 서두르시는 게 좋습니다.',
      visible: false,
    },
    {
      title: '계약기간은 어떻게 되나요?',
      content: '처음 계약은 2년이고, 재계약 시점에 갱신을 결정하시면 됩니다.',
      visible: false,
    },
    {
      title: '캠핑카 차량 관리는 어떻게 하나요?',
      content:
        '캠핑카의 차량 관리는 기본적으로 공유자가 관리해야 합니다. 하지만 년 1회 엔진오일 교환 비용은 지원되며, 추후 렌트캠과 협약 된 수리업체에서 진행 시 수리비 부담 또한 경감될 예정입니다.(준비 중)',
      visible: false,
    },
    {
      title: '차량의 보험은 어떻게 하나요?',
      content:
        '대물, 대인, 자손, 자차를 기본 가입하게 되고, 보험료는 기본료에 포함되어 있습니다. ',
      visible: false,
    },
    {
      title: '수익 정산은 어떤 프로세스로 진행되나요?',
      content:
        '이용자가 대금을 지불하면 기본료, 수수료, 세금 등을 제외하고 결제 대행 서비스를 통해 정산하게 됩니다.',
      visible: false,
    },
  ])
  const [ask, setAsk] = useState({
    name: '',
    phone: '',
    email: '',
    campingcar: true,
    content: '',
  })
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState(0)
  const { ref: refContainer1, inView: inView1 } = useInView()
  const { ref: refContainer2, inView: inView2 } = useInView()
  const { ref: refContainer3, inView: inView3 } = useInView()
  const { ref: refContainer4, inView: inView4 } = useInView()
  const { ref: refContainer5, inView: inView5 } = useInView()

  useEffect(() => {
    if (currentPage > 0) {
      setHeaderColor('white')
    } else {
      setHeaderColor('')
    }

    let containers = document.getElementsByClassName('container')
    for (let i = 0; i < containers.length; i++) {
      if (i === currentPage) {
        containers[i].classList.add('active')
      } else {
        containers[i].classList.remove('active')
      }
    }
  }, [currentPage])

  const handleLoad = (e: BaseSyntheticEvent) => {
    if (!e.currentTarget.classList.contains('active')) {
      e.currentTarget.classList.add('active')
    }
  }

  useEffect(() => {
    const onScroll = () => {
      if (window.innerHeight < window.scrollY) {
        setHeaderColor('white')
      } else {
        setHeaderColor('')
      }
    }
    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const setVisible = (idx: number) => {
    setFaq(
      faq.map((val, i) =>
        i === idx
          ? Object.assign(val, { visible: !val.visible })
          : Object.assign(val, { visible: false }),
      ),
    )
  }

  const handlePageChange = (number: number) => {
    setCurrentPage(number)
  }

  const handleBeforePageChange = (number: number) => {
    console.log(number)
  }

  return (
    <>
      <div
        className='fixed top-0 w-full h-16 sm:h-18 xl:h-21 flex items-center justify-end xl:justify-center pr-6 sm:pr-30 xl:pr-0 z-10'
        style={{
          backgroundColor: headerColor,
          border: headerColor.length > 0 ? 'solid 1px var(--border-1)' : '',
        }}
      >
        <CursorImg
          className='absolute hidden sm:block sm:left-30.5 xl:left-36'
          onClick={() => history.push('/')}
          src={
            headerColor.length > 0
              ? '/images/icon-nav-logo-2.svg'
              : '/images/icon-nav-logo.svg'
          }
          alt=''
        />
        <CursorImg
          className='absolute sm:hidden left-6'
          src={
            headerColor.length > 0
              ? '/images/group-523-primary.svg'
              : '/images/group-523.svg'
          }
          onClick={() => history.push('/')}
          alt=''
        />
        <div className='flex gap-8'>
          <button
            className='sm:Header2 Title1Bold flex items-center gap-2 text-gray-40'
            onClick={() => history.push('/')}
          >
            <img
              className='sm:w-8 w-6'
              src='/images/icon-nav-user-inactive.svg'
              alt=''
            />
            이용자
          </button>
          <button className='sm:Header2 Title1Bold flex items-center gap-2 text-primary-60'>
            <img
              className='sm:w-8 w-6'
              src='/images/icon-nav-share-active.svg'
              alt=''
            />
            공유자
          </button>
        </div>
      </div>
      {window.innerWidth <= 640 ? (
        <SharerMobile
          faq={faq}
          ask={ask}
          setAsk={setAsk}
          menu={menu}
          setMenu={setMenu}
          third={third}
          setThird={setThird}
          setVisible={setVisible}
        />
      ) : (
        <>
          <FirstContainer
            ref={refContainer1}
            onLoad={(e) => handleLoad(e)}
            style={{ backgroundImage: 'url("/images/bg-2.jpg")' }}
            className={`container w-full h-full bg-no-repeat bg-center flex bg-cover justify-center items-center flex-col relative ${inView1 ? 'active' : ''}`}
          >
            <div
              className='flex flex-col w-screen min-h-screen items-center sm:justify-center justify-evenly sm:gap-13 xl:gap-28'>
              <div className='Display2Bold sm:Display2Semibold-2 xl:Display1Bold text-white text-center'>
                많은 사람들이 <br className='sm:hidden' /> 여행을 통해
                <br />
                힐링 됐으면 좋겠습니다
              </div>

              <img
                className='sm:w-123 w-5/6'
                src='/images/chatbubble-desktop.svg'
                alt=''
              />

              <div style={{ display: 'flex', gap: '26px' }}>
                <a
                  href='https://play.google.com/store/apps/details?id=kr.rentcamp.app'
                  target="_blank"
                >
                  <img
                    className='w-28.5 sm:w-35'
                    src='/images/img-googleplay.svg'
                    alt=''
                  />
                </a>
                <a
                  href='https://apps.apple.com/us/app/렌트캠-rentcamp/id1633095843'
                  target="_blank"
                >
                  <img
                    className='w-28.5 sm:w-35'
                    src='/images/img-appstore.svg'
                    alt=''
                  />
                </a>
              </div>
            </div>
          </FirstContainer>
          {window.innerWidth <= 1280 ? (
            <SecondTabletContainer1
              ref={refContainer2}
              className={`container w-full min-h-screen justify-center bg-no-repeat bg-center flex bg-cover items-center flex-col gap-10 relative ${inView2 ? 'active' : ''}`}
              style={{ backgroundImage: 'url(/images/bg-3.jpg)' }}
            >
              <div
                className='flex xl:gap-10.5 flex-col xl:flex-row w-screen xl:pl-22 min-h-screen items-center justify-center'>
                <div
                  className='pl-6 pr-6 sm:pl-0 sm:pr-0 gap-10 sm:w-103.5 xl:w-4/12 xl:justify-start flex justify-center flex-col min-h-screen xl:min-h-0'>
                  <div
                    className='font-bold'
                    style={{
                      fontSize: '34px',
                    }}
                  >
                    안전한 수익구조의 렌트캠
                  </div>
                  <div className='flex flex-col gap-8'>
                    <div className='flex gap-4 flex-col'>
                      <div className='flex gap-2'>
                        <img className='w-6' src='/images/ic-check.svg' />
                        <div className='Title1Bold text-primary-60'>안정성</div>
                      </div>
                      <div className='text-gray-90 Title1Medium'>
                        과학기술정보통신부, 국토교통부를 통한 실증특례
                        규제샌드박스 허가
                      </div>
                    </div>
                    <div className='flex gap-4 flex-col'>
                      <div className='flex gap-2'>
                        <img className='w-6' src='/images/ic-check.svg' />
                        <div className='Title1Bold text-primary-60'>수익성</div>
                      </div>
                      <div className='text-gray-90 Title1Medium'>
                        월 8회, 5년간 수익 1억원 이상 (대여료 25만원 기준,
                        대여료에 따라 수익금은 증감가능)
                      </div>
                    </div>
                    <div className='flex gap-4 flex-col'>
                      <div className='flex gap-2'>
                        <img className='w-6' src='/images/ic-check.svg' />
                        <div className='Title1Bold text-primary-60'>효율성</div>
                      </div>
                      <div className='text-gray-90 Title1Medium'>
                        내가 사용하지 않을 때 빌려주어 효율성 보장
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SecondTabletContainer1>
          ) : null}
          {window.innerWidth <= 1280 ? (
            <SecondTabletContainer2
              ref={refContainer2}
              className={`container w-full min-h-screen justify-center bg-no-repeat bg-center flex bg-cover items-center flex-col gap-10 relative ${inView2 ? 'active' : ''}`}
              style={{ backgroundImage: 'url(/images/bg-3.jpg)' }}
            >
              <div className='flex flex-col w-screen min-h-screen items-center justify-center'>
                <div className='pl-6 pr-6 gap-10 flex justify-center flex-col min-h-screen'>
                  <div className='w-full min-h-screen flex items-center'>
                    <div
                      className='w-full sm:w-auto pt-16 sm:pt-12.5 sm:pb-12.5 sm:pl-13.75 sm:pr-13.75 sm:bg-white sm:border border-border-2'
                      style={{
                        borderRadius: '1.5rem',
                        boxShadow: '0 3px 5px 0 rgba(38, 40, 43, 0.04)',
                      }}
                    >
                      <div className='flex flex-col sm:flex-row gap-6 mb-6.5 sm:mb-8.5'>
                        <div className='Header1 sm:Display2Semibold'>
                          예시 월 수익
                        </div>
                        <div className='flex gap-4 w-fit'>
                          <button
                            className='border-none bg-none outline-none'
                            onClick={() => setMenu(1)}
                          >
                            <img
                              className='xl:w-28 w-24'
                              src={
                                menu === 1
                                  ? '/images/motorhome-active.svg'
                                  : '/images/motorhome-inactive.svg'
                              }
                            />
                          </button>
                          <button
                            className='border-none bg-none outline-none'
                            onClick={() => setMenu(2)}
                          >
                            <img
                              className='xl:w-43.5 w-36.5'
                              src={
                                menu === 2
                                  ? '/images/motorhome+option-active.svg'
                                  : '/images/motorhome+option-inactive.svg'
                              }
                            />
                          </button>
                          <button
                            className='border-none bg-none outline-none'
                            onClick={() => setMenu(3)}
                          >
                            <img
                              className='xl:w-28 w-24'
                              src={
                                menu === 3
                                  ? '/images/campervan-active.svg'
                                  : '/images/campervan-inactive.svg'
                              }
                            />
                          </button>
                        </div>
                      </div>
                      {menu === 1 ? (
                        <img
                          className='sm:w-167.5 xl:w-237 hidden sm:block'
                          src='/images/img-monthlyrevenue-1-desktop.svg'
                        />
                      ) : null}
                      {menu === 2 ? (
                        <img
                          className='sm:w-167.5 xl:w-237 hidden sm:block'
                          src='/images/img-monthlyrevenue-2-desktop.svg'
                        />
                      ) : null}
                      {menu === 3 ? (
                        <img
                          className='sm:w-167.5 xl:w-237 hidden sm:block'
                          src='/images/img-monthlyrevenue-3-desktop.svg'
                        />
                      ) : null}
                      {/* mobile */}
                      {menu === 1 ? (
                        <img
                          className='w-full sm:hidden'
                          src='/images/img-graph-motorhome-mobile.svg'
                        />
                      ) : null}
                      {menu === 2 ? (
                        <img
                          className='w-full sm:hidden'
                          src='/images/img-graph-motorhome-option-mobile.svg'
                        />
                      ) : null}
                      {menu === 3 ? (
                        <img
                          className='w-full sm:hidden'
                          src='/images/img-graph-campervan-mobile.svg'
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </SecondTabletContainer2>
          ) : null}
          {window.innerWidth > 1280 ? (
            <SecondContainer
              ref={refContainer2}
              className={`container w-full min-h-screen bg-no-repeat bg-center flex bg-cover items-center justify-center flex-col gap-10 relative ${inView2 ? 'active' : ''}`}
              style={{ backgroundImage: 'url(/images/bg-3.jpg)' }}
            >
              <div
                className='flex xl:gap-10.5 flex-col xl:flex-row w-screen xl:pl-22 min-h-screen items-center justify-center'>
                <div
                  className='pl-6 pr-6 sm:pl-0 sm:pr-0 gap-10 sm:w-103.5 xl:w-4/12 xl:justify-start flex justify-center flex-col min-h-screen xl:min-h-0'>
                  <div
                    className='font-bold'
                    style={{
                      fontSize: '34px',
                    }}
                  >
                    안전한 수익구조의 렌트캠
                  </div>
                  <div className='flex flex-col gap-8'>
                    <div className='flex gap-4 flex-col'>
                      <div className='flex gap-2'>
                        <img className='w-6' src='/images/ic-check.svg' />
                        <div className='Title1Bold text-primary-60'>안정성</div>
                      </div>
                      <div className='text-gray-90 Title1Medium'>
                        과학기술정보통신부, 국토교통부를 통한 실증특례
                        규제샌드박스 허가
                      </div>
                    </div>
                    <div className='flex gap-4 flex-col'>
                      <div className='flex gap-2'>
                        <img className='w-6' src='/images/ic-check.svg' />
                        <div className='Title1Bold text-primary-60'>수익성</div>
                      </div>
                      <div className='text-gray-90 Title1Medium'>
                        월 8회, 5년간 수익 1억원 이상 (대여료 25만원 기준,
                        대여료에 따라 수익금은 증감가능)
                      </div>
                    </div>
                    <div className='flex gap-4 flex-col'>
                      <div className='flex gap-2'>
                        <img className='w-6' src='/images/ic-check.svg' />
                        <div className='Title1Bold text-primary-60'>효율성</div>
                      </div>
                      <div className='text-gray-90 Title1Medium'>
                        내가 사용하지 않을 때 빌려주어 효율성 보장
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-auto xl:pr-26 min-h-screen xl:min-h-0 flex items-center xl:block'>
                  <div
                    className='w-full sm:w-auto pl-3.5 pr-3.5 sm:pt-12.5 sm:pb-12.5 sm:pl-13.75 sm:pr-13.75 sm:bg-white sm:border border-border-2'
                    style={{
                      borderRadius: '1.5rem',
                      boxShadow: '0 3px 5px 0 rgba(38, 40, 43, 0.04)',
                    }}
                  >
                    <div className='flex flex-col sm:flex-row gap-6 mb-8.5'>
                      <div className='Header1 sm:Display2Semibold'>
                        예시 월 수익
                      </div>
                      <div className='flex gap-4 w-fit'>
                        <button
                          className='border-none bg-none outline-none'
                          onClick={() => setMenu(1)}
                        >
                          <img
                            className='xl:w-28 w-24'
                            src={
                              menu === 1
                                ? '/images/motorhome-active.svg'
                                : '/images/motorhome-inactive.svg'
                            }
                          />
                        </button>
                        <button
                          className='border-none bg-none outline-none'
                          onClick={() => setMenu(2)}
                        >
                          <img
                            className='xl:w-43.5 w-36.5'
                            src={
                              menu === 2
                                ? '/images/motorhome+option-active.svg'
                                : '/images/motorhome+option-inactive.svg'
                            }
                          />
                        </button>
                        <button
                          className='border-none bg-none outline-none'
                          onClick={() => setMenu(3)}
                        >
                          <img
                            className='xl:w-28 w-24'
                            src={
                              menu === 3
                                ? '/images/campervan-active.svg'
                                : '/images/campervan-inactive.svg'
                            }
                          />
                        </button>
                      </div>
                    </div>
                    {menu === 1 ? (
                      <img
                        className='sm:w-167.5 xl:w-237 hidden sm:block'
                        src='/images/img-monthlyrevenue-1-desktop.svg'
                      />
                    ) : null}
                    {menu === 2 ? (
                      <img
                        className='sm:w-167.5 xl:w-237 hidden sm:block'
                        src='/images/img-monthlyrevenue-2-desktop.svg'
                      />
                    ) : null}
                    {menu === 3 ? (
                      <img
                        className='sm:w-167.5 xl:w-237 hidden sm:block'
                        src='/images/img-monthlyrevenue-3-desktop.svg'
                      />
                    ) : null}
                    {/* mobile */}
                    {menu === 1 ? (
                      <img
                        className='w-full sm:hidden'
                        src='/images/img-graph-motorhome-mobile.svg'
                      />
                    ) : null}
                    {menu === 2 ? (
                      <img
                        className='w-full sm:hidden'
                        src='/images/img-graph-motorhome-option-mobile.svg'
                      />
                    ) : null}
                    {menu === 3 ? (
                      <img
                        className='w-full sm:hidden'
                        src='/images/img-graph-campervan-mobile.svg'
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <button className='border-none bg-none outline-none absolute right-0 bottom-12 hidden xl:block'
                      onClick={() => window.open('http://pf.kakao.com/_sgrfxj/chat', '_blank')}>
                <img className='' src='/images/img-talk.svg' />
              </button>
            </SecondContainer>
          ) : null}
          <ThirdContainer
            ref={refContainer3}
            className={`container w-full min-h-screen bg-no-repeat bg-center justify-center flex bg-cover items-center flex-col relative sm:pb-18 xl:pb-0 ${inView3 ? 'active' : ''}`}
            style={{ backgroundImage: 'url("/images/bg-4.jpg")' }}
          >
            <div
              className='flex w-screen min-h-screen items-center justify-center gap-5.5 sm:gap-13 flex-col pt-16 sm:pt-0 pl-6 pr-6 sm:pl-0 sm:pr-0 sm:mt-0'>
              <div className='Header1 sm:Display2Bold sm:mt-18 xl:mt-0'>
                공유자 등록 안내
              </div>
              <div className='xl:w-271.5 sm:w-187.5 bg-white rounded sm:rounded-lg overflow-hidden'>
                <div className='grid xl:h-20 sm:h-15 h-10 grid-cols-2'>
                  <button
                    className={`xl:Header1 sm:Title2 Title4Bold ${
                      !third
                        ? 'text-primary-60'
                        : 'text-gray-60 bg-background-dark'
                    }`}
                    onClick={() => setThird(false)}
                  >
                    캠핑카 구매 희망자
                  </button>
                  <button
                    className={`xl:Header1 sm:Title2 Title4Bold ${
                      third
                        ? 'text-primary-60'
                        : 'text-gray-60 bg-background-dark'
                    }`}
                    onClick={() => setThird(true)}
                  >
                    캠핑카 보유자
                  </button>
                </div>
                {!third ? (
                  <>
                    <div
                      className='flex pt-6 pl-4 pr-4 sm:pl-10 sm:pr-10 xl:pt-15.5 sm:pt-8 xl:pb-13 sm:pb-8 flex-col items-center gap-1.5 sm:gap-0'>
                      <div
                        className='flex w-full sm:pl-6 sm:h-20 bg-background-light rounded-xl sm:items-center flex-col sm:flex-row gap-3 sm:gap-0 pt-3.5 pl-3 pr-3 pb-3.5 sm:pt-0 sm:pb-0 sm:pr-0'>
                        <div className='flex items-center sm:w-2/5'>
                          <img
                            className='w-6 mr-3'
                            src='/images/icon-info-1-primary.svg'
                          />
                          <div className='xl:Title1Bold sm:Title2 text-gray-90 Title3Bold'>
                            렌트캠 공유자 가입 문의
                          </div>
                        </div>
                        <div className='xl:Body1 sm:Body2 Title4Medium'>
                          유선, 이메일, 카톡을 통한 상담 진행
                        </div>
                      </div>
                      <img className='w-8' src='/images/arrow.svg' />
                      <div
                        className='flex w-full sm:pl-6 sm:h-20 bg-background-light rounded-xl sm:items-center flex-col sm:flex-row gap-3 sm:gap-0 pt-3.5 pl-3 pr-3 pb-3.5 sm:pt-0 sm:pb-0 sm:pr-0'>
                        <div className='flex items-center sm:w-2/5'>
                          <img
                            className='w-6 mr-3'
                            src='/images/icon-info-2-primary.svg'
                          />
                          <div className='xl:Title1Bold sm:Title2 text-gray-90 Title3Bold'>
                            렌트캠 공유자 계약 및{' '}
                            <br className='hidden sm:block xl:hidden' /> 캠핑카
                            구입 진행
                          </div>
                        </div>
                        <div className='xl:Body1 sm:Body2 Title4Medium'>
                          렌트캠 공유 계약 작성 후 원하는 제작업체에서 차량 출고
                        </div>
                      </div>
                      <img className='w-8' src='/images/arrow.svg' />
                      <div
                        className='flex w-full sm:pl-6 sm:h-20 bg-background-light rounded-xl sm:items-center flex-col sm:flex-row gap-3 sm:gap-0 pt-3.5 pl-3 pr-3 pb-3.5 sm:pt-0 sm:pb-0 sm:pr-0'>
                        <div className='flex items-center sm:w-2/5'>
                          <img
                            className='w-6 mr-3'
                            src='/images/icon-info-3-primary.svg'
                          />
                          <div className='xl:Title1Bold sm:Title2 text-gray-90 Title3Bold'>
                            캠핑카 출고 후
                          </div>
                        </div>
                        <div className='xl:Body1 sm:Body2 Title4Medium'>
                          캠핑카 출고 후 사용하지 않는 기간 동안 렌트캠 공유를
                          통한 수익 창출
                        </div>
                      </div>
                    </div>

                  </>
                ) : null}
                {third ? (
                  <div
                    className='xl:pt-15.5 sm:pt-10 flex flex-col items-center justify-center pt-6 gap-6 sm:gap-0 pb-21 sm:pb-0 pl-4 pr-4 sm:pl-0 sm:pr-0'>
                    <div className='flex flex-col gap-4.5 items-center'>
                      <img className='w-6' src='/images/ic-bang.svg' />
                      <div className='text-primary-80 xl:Title1Bold sm:Title2 Title3Bold'>
                        출고후 3년이내 차량만 등록 가능
                      </div>
                    </div>

                    <div
                      className='flex w-full pl-4 pr-4 sm:pl-10 sm:pr-10 xl:pt-15.5 sm:pt-8 xl:pb-13 sm:pb-8 flex-col items-center gap-1.5 sm:gap-0'>
                      <div
                        className='flex w-full sm:pl-6 sm:h-20 bg-background-light rounded-xl sm:items-center flex-col sm:flex-row gap-3 sm:gap-0 pt-3.5 pl-3 pr-3 pb-3.5 sm:pt-0 sm:pb-0 sm:pr-0'>
                        <div className='flex items-center sm:w-2/5'>
                          <img
                            className='w-6 mr-3'
                            src='/images/icon-info-1-secondary.svg'
                          />
                          <div className='xl:Title1Bold sm:Title2 Title3Bold text-gray-90'>
                            렌트캠 공유자 가입 문의
                          </div>
                        </div>
                        <div className='xl:Body1 sm:Body2 Title4Medium'>
                          유선, 이메일, 카톡을 통한 상담 진행
                        </div>
                      </div>
                      <img className='w-8' src='/images/arrow.svg' />
                      <div
                        className='flex w-full sm:pl-6 sm:h-20 bg-background-light rounded-xl sm:items-center flex-col sm:flex-row gap-3 sm:gap-0 pt-3.5 pl-3 pr-3 pb-3.5 sm:pt-0 sm:pb-0 sm:pr-0'>
                        <div className='flex items-center sm:w-2/5'>
                          <img
                            className='w-6 mr-3'
                            src='/images/icon-info-2-secondary.svg'
                          />
                          <div className='xl:Title1Bold sm:Title2 Title3Bold text-gray-90'>
                            서류 작성
                          </div>
                        </div>
                        <div className='xl:Body1 sm:Body2 Title4Medium'>
                          렌트캠 공유자 계약서 작성
                        </div>
                      </div>
                      <img className='w-8' src='/images/arrow.svg' />
                      <div
                        className='flex w-full sm:pl-6 sm:h-20 bg-background-light rounded-xl sm:items-center flex-col sm:flex-row gap-3 sm:gap-0 pt-3.5 pl-3 pr-3 pb-3.5 sm:pt-0 sm:pb-0 sm:pr-0'>
                        <div className='flex items-center sm:w-2/5'>
                          <img
                            className='w-6 mr-3'
                            src='/images/icon-info-3-secondary.svg'
                          />
                          <div className='xl:Title1Bold sm:Title2 Title3Bold text-gray-90'>
                            캠핑카 출고 후
                          </div>
                        </div>
                        <div className='xl:Body1 sm:Body2 Title4Medium'>
                          사용하지 않는 기간 동안 렌트캠 공유를 통한 수익 창출
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <button className='xl:block hidden border-none bg-none outline-none absolute right-0 bottom-12'
                    onClick={() => window.open('http://pf.kakao.com/_sgrfxj/chat', '_blank')}>
              <img className='' src='/images/img-talk.svg' />
            </button>
          </ThirdContainer>
          <FourthContainer
            ref={refContainer4}
            className={`container w-full bg-no-repeat bg-center flex bg-cover sm:justify-center items-center flex-col relative gap-5.5 sm:gap-10 min-h-screen pt-16 pl-6 pr-6 sm:pl-0 sm:pr-0 overflow-hidden ${inView4 ? 'active' : ''}`}
            style={{
              backgroundImage: 'url("/images/bg-3.jpg")',
            }}
          >
            <div className='text-gray-80 xl:Display2Semibold Header1 mt-5.5 sm:mt-0'>
              궁금한 점이 있으신가요?
            </div>
            <div className='xl:w-271.5 sm:w-3/4 bg-white border-t-2 border-b border-border-2 sm:mb-12 xl:mb-0'>
              {faq.map((val, idx) => {
                return (
                  <div
                    key={idx}
                    onClick={() => {
                      setVisible(idx)
                    }}
                  >
                    <div
                      className='sm:h-12 xl:h-16 h-16 border-b border-border-2 flex flex-row items-center justify-between pl-4 pr-3.5'>
                      <div
                        className='xl:Title1Bold sm:Title4Medium Title3Medium text-gray-90 flex flex-row items-center'>
                        <img
                          className='w-6 mr-8.5'
                          src='/images/ic-question.svg'
                        />
                        {val.title}
                      </div>
                      <img
                        className={`w-6 ${val.visible ? 'rotate-180' : ''}`}
                        src={'/images/arrow-up-s-line.svg'}
                      />
                    </div>
                    <div
                      className={`bg-background-dark ${
                        val.visible ? '' : 'hidden'
                      } flex pl-4.5 pt-5 pb-5 pr-3.5 items-start xl:Body1 sm:Body2 Body3`}
                    >
                      <img className='w-6 mr-8.5' src='/images/ic-answer.svg' />
                      {val.content}
                    </div>
                  </div>
                )
              })}
            </div>
            <button className='hidden xl:block border-none bg-none outline-none absolute right-0 bottom-12'
                    onClick={() => window.open('http://pf.kakao.com/_sgrfxj/chat', '_blank')}>
              <img className='' src='/images/img-talk.svg' />
            </button>
          </FourthContainer>
          <FifthContainer
            ref={refContainer5}
            className={`container w-full bg-no-repeat bg-cover bg-center flex justify-center items-center flex-col relative min-h-screen sm:pb-20 xl:pb-0 pt-16 sm:pt-0 gap-5.5 sm:gap-0 pl-6 pr-6 sm:pl-0 sm:pr-0 ${inView5 ? 'active' : ''}`}
            style={{
              backgroundImage: 'url("/images/bg-4.jpg")',
            }}
          >
            <div className='xl:Display2Semibold-2 Header1 sm:mt-21 xl:mb-10 sm:mb-6 mt-5.5'>
              공유자 가입 문의
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                setDoc(doc(collection(db, 'mailQueue')), {
                  from: 'dydgk6678@gmail.com',
                  to: 'rentcamp365@naver.com',
                  message: {
                    subject: '렌트캠 공유자 가입 문의',
                    html: `Email : ${ask.email} <br />
Name: ${ask.name} <br />
Phone: ${ask.phone} <br />
Having CampingCar: ${ask.campingcar ? 'true' : 'false'} <br />
Message: ${ask.content}`,
                  },
                }).then(() => {
                  alert('문의가 접수되었습니다.')
                  setAsk({
                    name: '',
                    phone: '',
                    email: '',
                    campingcar: true,
                    content: '',
                  })

                })
              }}
              className='xl:w-202.5 sm:w-3/5 w-full sm:p-10 p-7.5 bg-white rounded-lg border border-border-2 flex flex-col gap-5'
              style={{ boxShadow: '0 3px 5px 0 rgba(38, 40, 43, 0.04)' }}
            >
              <input
                className='border border-border-2 rounded xl:h-15 sm:h-12 h-11 pl-2.5 sm:pl-3 xl:placeholder:Body1 sm:placeholder:Body2 placeholder:text-gray-70 outline-none text-xs sm:text-base'
                type='text'
                value={ask.name}
                onChange={(e) =>
                  setAsk({ ...ask, name: e.currentTarget.value })
                }
                placeholder='성함'
                required
              />
              <input
                className='border border-border-2 rounded xl:h-15 sm:h-12 h-11 pl-2.5 sm:pl-3 xl:placeholder:Body1 sm:placeholder:Body2 placeholder:text-gray-70 outline-none text-xs sm:text-base'
                type='text'
                value={ask.phone}
                onChange={(e) =>
                  setAsk({ ...ask, phone: e.currentTarget.value })
                }
                placeholder='연락처'
                required
              />
              <input
                className='border border-border-2 rounded xl:h-15 sm:h-12 h-11 pl-2.5 sm:pl-3 xl:placeholder:Body1 sm:placeholder:Body2 placeholder:text-gray-70 outline-none text-xs sm:text-base'
                type='text'
                value={ask.email}
                onChange={(e) =>
                  setAsk({ ...ask, email: e.currentTarget.value })
                }
                placeholder='이메일'
                required
              />
              <div className='flex sm:gap-8 gap-3'>
                <div
                  className={`flex items-center w-full xl:h-14 sm:h-12 h-11 border rounded ${
                    ask.campingcar
                      ? 'border-primary-60'
                      : 'border-border-2 bg-background-light'
                  }`}
                  onClick={() => setAsk({ ...ask, campingcar: true })}
                >
                  <img
                    className='sm:w-6 w-4.5 sm:ml-2 sm:mr-2 ml-1.5 mr-1.5'
                    src={
                      ask.campingcar
                        ? '/images/ic-radio-active.svg'
                        : '/images/ic-radio-inactive.svg'
                    }
                  />
                  <div
                    className={`${
                      ask.campingcar
                        ? 'xl:Title3Bold sm:Title4Bold Title5Bold'
                        : 'xl:Title3Medium sm:Title4Medium Title5Medium'
                    }`}
                  >
                    캠핑카 보유중
                  </div>
                </div>
                <div
                  className={`flex items-center w-full xl:h-14 sm:h-12 h-11 border rounded ${
                    !ask.campingcar
                      ? 'border-primary-60'
                      : 'border-border-2 bg-background-light'
                  }`}
                  onClick={() => setAsk({ ...ask, campingcar: false })}
                >
                  <img
                    className='sm:ml-2 sm:mr-2 ml-1.5 mr-1.5 sm:w-6 w-4.5'
                    src={
                      !ask.campingcar
                        ? '/images/ic-radio-active.svg'
                        : '/images/ic-radio-inactive.svg'
                    }
                  />
                  <div
                    className={`${
                      !ask.campingcar
                        ? 'xl:Title3Bold sm:Title4Bold Title5Bold'
                        : 'xl:Title3Medium sm:Title4Medium Title5Medium'
                    }`}
                  >
                    캠핑카 구매예정
                  </div>
                </div>
              </div>
              <textarea
                className='w-full xl:h-59 sm:h-38.5 h-46.5 border border-border-2 rounded pt-4 pb-4 pl-3 pr-4 placeholder:text-gray-70 xl:placeholder:Body1 sm:placeholder:Body2 outline-none  text-xs sm:text-base'
                value={ask.content}
                onChange={(e) =>
                  setAsk({ ...ask, content: e.currentTarget.value })
                }
                placeholder='문의사항'
                required
              />
              <button
                type='submit'
                className='sm:mt-9 mt-7 bg-primary-60 text-white rounded sm:h-12.5 h-10 Title3Bold'
              >
                문의 전송
              </button>
            </form>
            <button className='hidden xl:block border-none bg-none outline-none absolute right-0 bottom-12'
                    onClick={() => window.open('http://pf.kakao.com/_sgrfxj/chat', '_blank')}>
              <img className='' src='/images/img-talk.svg' />
            </button>
          </FifthContainer>
        </>
      )}
      {(currentPage === 4 || window.innerWidth <= 640) && <Footer
        className='container w-full'
      />}
    </>
  )
}

const FirstContainer = styled.div`
  > div {
    transform: translateY(150px);
    opacity: 0;
    transition: 2s all ease;
  }

  &.active > div {
    transform: translateY(0);
    opacity: 1;
  }
`
const SecondContainer = styled.div`
  > div > div:first-child {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 2s all ease;
  }

  > div > div:nth-child(2) {
    position: relative;
    opacity: 0;
    transition: 2s all ease;
  }

  &.active > div > div:first-child {
    transform: translateY(0);
    opacity: 1;
  }

  &.active > div > div:nth-child(2) {
    opacity: 1;
  }
`
const ThirdContainer = styled.div`
  > div > .Header1 {
    opacity: 0;
    transition: 2s all ease;
  }

  &.active > div > .Header1 {
    opacity: 1;
  }

  > div > div:nth-child(2) {
    opacity: 0;
    transform: translateY(150px);
    transition: 2s all ease;
  }

  &.active > div > div:nth-child(2) {
    transform: translateY(0);
    opacity: 1;
  }
`
const FourthContainer = styled.div`
  > .Header1 {
    opacity: 0;
    transition: 2s all ease;
  }

  &.active > .Header1 {
    opacity: 1;
  }

  > div:nth-child(2) {
    opacity: 0;
    transform: translateY(150px);
    transition: 2s all ease;
  }

  &.active > div:nth-child(2) {
    transform: translateY(0);
    opacity: 1;
  }
`
const FifthContainer = styled.div`
  > .Header1 {
    opacity: 0;
    transition: 2s all ease;
  }

  &.active > .Header1 {
    opacity: 1;
  }

  > form {
    opacity: 0;
    transform: translateY(150px);
    transition: 2s all ease;
  }

  &.active > form {
    transform: translateY(0);
    opacity: 1;
  }
`
const SecondTabletContainer1 = styled.div`
  > div > div:first-child {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 2s all ease;
  }

  &.active > div > div:first-child {
    transform: translateY(0);
    opacity: 1;
  }
`
const SecondTabletContainer2 = styled.div`
  > div > div:first-child {
    position: relative;
    opacity: 0;
    transition: 2s all ease;
  }

  &.active > div > div:first-child {
    opacity: 1;
  }
`

export default Sharer
